.inactiveArrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    margin-left: 5px;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg)
}

.activeArrow {
    border: solid green;
    border-width: 0 3px 3px 0;
    margin-left: 5px;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg)
}

.birthdayRowItem:hover {
    cursor: pointer;
}