#header {
    border: 5px solid black;
    border-radius: 25px;
    padding: 25px 50px 50px 50px;
    flex-shrink: 0;
    font-size: 30px;
}

#links {
    font-size: 20px;
    margin: 15px;
    padding: 20px;
    width: 100%;
}

#links li {
    text-decoration: none;
    color: rgb(220, 229, 235);
    list-style-type: none;
    float: left;
    margin: 10px;
    padding: 5px;
    text-align: center;
}

a:hover {
    text-decoration: none;
    color: white;
}

a:focus {
    outline: none;
}

Button {
    margin: 8px
}

#saveChangesButton:focus {
    outline: none;
}

